/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.11.3/dist/jquery.min.js
 * - /npm/jquery-mask-plugin@1.14.16/dist/jquery.mask.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/select2@4.1.0-rc.0/dist/js/select2.min.js
 * - /npm/jquery-countdown@2.2.0/dist/jquery.countdown.min.js
 * - /npm/wowjs@1.1.3/dist/wow.min.js
 * - /npm/bootbox@4.3.0/bootbox.min.js
 * - /npm/jquery-price-format@0.0.1/jquery.priceformat.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
